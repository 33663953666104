import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/MainGame.vue'
import LeaderBoards from '@/views/LeaderBoards.vue'
import AboutPage from '@/views/AboutPage.vue'

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', component: Home, name: 'Home' },
  { path: '/leaderboards/:mode?', component: LeaderBoards, name: 'Leaderboards' },
  { path: '/about', component: AboutPage, name: 'About' },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  export default router