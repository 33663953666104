<template>
  <v-container>
    <!-- Progress line -->
    <v-progress-linear
      v-if="this.$store.state.practice"
      rounded
      v-model="progressValue"
      value="20"
      height="25"
      color="#1867C0"
    >
      <strong>{{ questionNumber }}</strong>
    </v-progress-linear>
    <div v-if="this.$store.state.survival" id="lives">
      <div>
        <v-icon
          v-for="i in 5 - this.$store.state.wrongAnswers"
          :key="i"
          class="heart"
          >mdi-heart</v-icon
        >
        <v-icon
          v-for="i in this.$store.state.wrongAnswers"
          :key="i"
          class="heart-broken"
          >mdi-heart-broken</v-icon
        >
      </div>
      <div
        id="score"
        v-if="this.$store.state.survival || $store.state.ultimate"
      >
        <span>Score: {{ this.$store.state.score }}</span>
        <span>Best<span v-if="$vuetify.display.mdAndUp"> Score</span>: {{ bestScore || '/' }}</span>
      </div>
    </div>
    <div id="actions">
      <div style="width: 48px">
        <v-btn
          icon
          id="reveal-btn"
          v-show="this.$store.state.ultimate"
          :disabled="answerSubmitted || currentCirclRadiusOption == circleRadiusOptions.length - 1"
          @click="currentCirclRadiusOption++"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </div>
      <div style="width: 30px">
        <div
          id="current-points"
          v-show="this.$store.state.ultimate && !answerSubmitted"
        >
          +{{ 20 - this.currentCirclRadiusOption * 5 }}
        </div>
      </div>
    </div>
    <!-- Main game -->
    <v-card id="mainCard" :key="questionNumber">
      <v-card id="flag-wrapper">
        <img
          :class="this.$store.state.ultimate ? 'flag circle' : 'flag'"
          :src="getImgUrl"
        />
      </v-card>
      <v-row id="navigation">
        <v-row id="answers" align="center">
          <v-btn
            v-for="option in shuffled"
            :key="option"
            :id="option + '-option'"
            :class="'choice {{ }'"
            @click="submitAnswer($event, option)"
            elevation="5"
          >
            {{ allCountries[option] }}
          </v-btn>
        </v-row>
      </v-row>
      <v-row id="next-wrapper">
        <v-btn
          id="next"
          v-show="answerSubmitted"
          @click="getNewQuestion"
          ripple
        >
          {{ getNextButtonText }}
        </v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
const tapSound = require("@/assets/audio/ui_tap-variant-01.ogg");
const celebrateSound = require("@/assets/audio/navigation_selection-complete-celebration.ogg");
const forwardSound = require("@/assets/audio/navigation_forward-selection-minimal.ogg");
import shuffle from "@/services/helpers";
import { mapGetters } from "vuex";

export default {
  name: "MainGame",
  data: () => ({
    answerSubmitted: false,
    questionNumber: 1,
    circleRadiusOptions: [20, 40, 60, 120],
    currentCirclRadiusOption: 0,
  }),
  computed: {
    ...mapGetters(["bestScore"]),
    correctAnswer() {
      return this.$store.state.currentOptions[0];
    },
    allCountries() {
      return this.$store.state.countryNames;
    },
    shuffled() {
      let copy = JSON.parse(JSON.stringify(this.$store.state.currentOptions));
      return shuffle(copy);
    },
    progressValue() {
      return (100 / 20) * this.questionNumber;
    },
    getNextButtonText() {
      if (
        (this.$store.state.practice && this.questionNumber === 20) ||
        (this.$store.state.survival && this.$store.state.wrongAnswers === 5)
      ) {
        return "Continue";
      } else {
        return "Next";
      }
    },
    getImgUrl() {
      return require(`@/assets/flags/${this.allCountries[this.correctAnswer]
        .toLowerCase()
        .replaceAll(" ", "-")}_flag-svg.svg`);
    },
  },
  methods: {
    setCircleStart() {
      this.currentCirclRadiusOption = 0;
      let positionPool = [20, 40, 60, 80];
      var height =
        positionPool[Math.floor(Math.random() * positionPool.length)];
      var width = positionPool[Math.floor(Math.random() * positionPool.length)];
      document.documentElement.style.setProperty(
        "--circleHeight",
        `${height}%`
      );
      document.documentElement.style.setProperty("--circleWidth", `${width}%`);
      document.documentElement.style.setProperty("--circleRadius", `20%`);
    },
    submitAnswer(el, option) {
      if (option === this.correctAnswer) {
        let audio = new Audio(celebrateSound);
        audio.play();
        el.target.style.color = "white";
        el.target.style.backgroundColor = "#4CAF50";
        if (!this.answerSubmitted) {
          this.$store.commit("incrementCorrectAnswers");
          if (this.$store.state.ultimate) {
            let points = 20 - this.currentCirclRadiusOption * 5;
            this.$store.commit("incrementScore", points);
          } else if (this.$store.state.survival) {
            this.$store.commit("incrementScore", 10);
          }
        }
      } else {
        let audio = new Audio(tapSound);
        audio.play();
        el.target.style.color = "red";
        el.target.style.backgroundColor = "pink";
        document
          .getElementById(this.correctAnswer + "-option")
          .setAttribute("style", "color:white;background-color:#4CAF50;");
        if (!this.answerSubmitted) {
          this.$store.commit("incrementWrongAnswers");
        }
      }

      if (this.$store.state.ultimate) {
        this.currentCirclRadiusOption = this.circleRadiusOptions.length - 1;
      }
      
      this.answerSubmitted = true;
    },
    getNewQuestion() {
      if (
        (this.$store.state.practice && this.questionNumber === 20) ||
        (this.$store.state.survival && this.$store.state.wrongAnswers === 5)
      ) {
        this.$store.commit("endGame");
        this.$store.dispatch("updateBestScore");
      }

      let audio = new Audio(forwardSound);
      audio.play();
      this.$store.dispatch("getNewQuestion");
      this.questionNumber++;
      this.answerSubmitted = false;
      this.setCircleStart();
    },
  },
  beforeCreate() {
    this.$store.dispatch("getNewQuestion");
  },
  beforeMount() {
    this.setCircleStart();
  },
  watch: {
    currentCirclRadiusOption(newVal) {
      document.documentElement.style.setProperty(
        "--circleRadius",
        `${this.circleRadiusOptions[newVal]}%`
      );
    },
  },
};
</script>

<style lang="scss">
@import "./mainGame.scss";
</style>
