<template>
  <v-container id="start-container">
    <!-- Page title -->
    <h1 id="logo">Flag Guesser</h1>
    <!-- Game options -->
    <div id="start-game-btn_cont">
      <v-btn @click="startPractice" id="start-game-btn" elevation="9">
        Practice
        <v-icon>mdi-arrow-right-drop-circle</v-icon>
      </v-btn>
      <v-btn @click="startSurvival" id="start-game-btn" elevation="9">
        Survival
        <v-icon>mdi-arrow-right-drop-circle</v-icon>
      </v-btn>
      <v-badge
        color="error"
        content="NEW!"
      >
      <v-btn @click="startUltimate" id="start-game-btn" elevation="9">
        Ultimate
        <v-icon>mdi-arrow-right-drop-circle</v-icon>
      </v-btn>
      </v-badge>
    </div>
  </v-container>
</template>

<script>
// import ColorSwitch from './ColorSwitch.vue';

const startSound = require("@/assets/audio/navigation_forward-selection.ogg");
export default {
  name: "StartPage",
  // components: { ColorSwitch },
  data: () => ({}),
  methods: {
    startPractice() {
      const audio = new Audio(startSound);
      audio.src = startSound;
      audio.play();
      this.$store.commit("startPractice");
    },
    startSurvival() {
      const audio = new Audio(startSound);
      audio.play();
      this.$store.commit("startSurvival");
      // Maybe add personal best with cookies
    },
    startUltimate() {
      const audio = new Audio(startSound);
      audio.play();
      this.$store.commit("startUltimate")
    }
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';

#start-container {
  display: flex;
  justify-content: flex-start;
}

#settings-row {
  display: flex;
  justify-content: space-between;

  .v-input {
    flex: none;
  }
}

#logo {
  margin-top: 30px;
  font-family: "Pacifico", cursive;
  text-align: center;
  font-size: 50px;
  font-weight: lighter;
  color: $textColor;
}
#start-game-btn_cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-around;
}

#start-game-btn {
  font-size: x-large;
  padding: 30px;
  width: 270px;

  &:hover {
    transform: scale(1.10);
  }
}

.mdi-arrow-right-drop-circle {
  padding-left: 20px;
}

@media (min-width: 992px) {
  #logo {
    margin-top: 50px;
    font-size: 80px;
  }
}
</style>
