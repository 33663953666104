<template>
          <StartPage v-if="!$store.state.start"/>
        <EndScreen v-else-if="$store.state.end" />
        <MainGame v-else/>
</template>

<script>
import MainGame from '@/components/MainGame.vue'
import StartPage from '@/components/StartPage.vue'
import EndScreen from '@/components/EndScreen.vue'

export default {
  components: {
    StartPage, MainGame, EndScreen
  },
}
</script>

<style>

</style>