import { createStore } from 'vuex'
import countryService from '@/services/countries'

// Create a new store instance.
export default createStore({
    state () {
      return {
        start: false,
        end: false,
        practice: false,
        survival: false,
        ultimate: false,
        countryContinents: countryService.getCountryContinents(),
        countryNames: countryService.getCountryNames(),
        countriesSoFar: [],
        currentOptions: [],
        correctAnswers: 0,
        wrongAnswers: 0,
        darkTheme: false,
        score: 0,
        isNewBestScore: false,
        bestScoreSurvival: localStorage.getItem("survival-best-score") || 0,
        bestScoreUltimate: localStorage.getItem("ultimate-best-score") || 0,
      }
    },
    mutations: {
      startSurvival(state) {
        state.start = true;
        state.survival = true;
      },
      startPractice(state) {
        state.start = true;
        state.practice = true;
      },
      startUltimate(state) {
        state.start = true;
        state.survival = true;
        state.ultimate = true;
      },
      restartGame(state) {
        state.start = false;
        state.end = false;
        state.survival = false;
        state.ultimate = false;
        state.practice = false;
        state.correctAnswers = 0;
        state.wrongAnswers = 0;
        state.score = 0;
        state.isNewBestScore = false;
      },
      endGame(state) {
        state.end = true;
      },
      addCountrySoFar(state, newCountry) {
        state.countriesSoFar.push(newCountry);
      },
      setCurrentOptions(state, newOptions) {
        state.currentOptions = newOptions;
      },
      incrementCorrectAnswers(state) {
        state.correctAnswers++;
      },
      incrementWrongAnswers(state) {
        state.wrongAnswers++;
      },
      incrementScore(state, points) {
        state.score += points;
      },
      toggleDarkTheme(state) {
        state.darkTheme = !state.darkTheme;
      },
      updateBestScoreSurvival(state, score) {
        state.bestScoreSurvival = score;
      },
      updateBestScoreUltimate(state, score) {
        state.bestScoreUltimate = score;
      },
      setIsNewBestScore(state) {
        state.isNewBestScore = true;
      }
    },
    actions: {
      getNewQuestion({ commit, state }) {
        let options = countryService.getOptions(state.countryNames, state.countryContinents, state.countriesSoFar)
        commit('addCountrySoFar', options[0])
        commit('setCurrentOptions', options)
      },
      updateBestScore({ state, getters, commit }) {
        if (state.score <= getters.bestScore) return;

        commit('setIsNewBestScore');
        if (state.ultimate) {
          commit('updateBestScoreUltimate', state.score)
          return localStorage.setItem("ultimate-best-score", state.score);
        } else if (state.survival) {
          commit('updateBestScoreSurvival', state.score)
          return localStorage.setItem("survival-best-score", state.score);
        }
      }
    },
    getters: {
      isDarkTheme: state => {
        return state.darkTheme;
      },
      bestScore: state => {
        if (state.ultimate) return state.bestScoreUltimate
        if (state.survival) return state.bestScoreSurvival
      },
      isNewBestScore: state => state.isNewBestScore
    }
  })