<template>
  <v-container id="end">
    <!-- Practice -->
    <template v-if="$store.state.practice">
      <v-img :src="medal" id="medal"></v-img>
      <div class="score">{{ $store.state.correctAnswers }} / 20</div>
      <v-btn id="submit-text" @click="restartGame">Finish</v-btn>
    </template>
    <!-- Survival OR Ultimate-->
    <template v-if="$store.state.survival || $store.state.ultimate">
      <div class="score">Your score:</div>
      <div class="score">{{ $store.state.score}}</div>
      <div class="score" v-if="isNewBestScore">New best!</div>
      <v-card id="submit-input-card" class="score">
          <v-card-content>
            <div id="enter-username-prompt">Enter your name to add your score to the leaderboard:</div>  
            <v-text-field autofocus label="Username" hide-details v-model="currentUsername"></v-text-field>
          </v-card-content>
          <v-card-actions>
            <v-btn id="submit-text" :disabled="!isFormValid" @click="submitScore">Submit</v-btn>
          </v-card-actions>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
const API_POST_URL = 'https://k8ghruqi04.execute-api.us-east-2.amazonaws.com/leaderboard/'

export default {
  data() {
    return {
      currentUsername: ''
    }
  },
  computed: {
    ...mapGetters(['isNewBestScore']),
    medal() {
      let percentage = (this.$store.state.correctAnswers * 100) / 20;
      switch (true) {
        case percentage >= 70:
          return require("@/assets/medals/gold-medal-svgrepo-com.svg");
        case percentage >= 35:
          return require("@/assets/medals/silver-medal-svgrepo-com.svg");
        default:
          return require("@/assets/medals/bronze-medal-svgrepo-com.svg");
      }
    },
    isFormValid() {
      console.log(this.$store.state)
      if (!this.currentUsername) return false;
      if ((this.currentUsername || '').length > 25) return false

      return true;
    },
  },
  methods: {
    restartGame() {
      this.$store.commit("restartGame");
    },
    submitScore() {
      let self = this;
      let mode = ''
      if (this.$store.state.ultimate) {
        mode += 'ultimate'
      } else if (this.$store.state.survival)  {
        mode += 'survival'
      }

      let url = API_POST_URL + mode;
      
      let body = {
        score: self.$store.state.score,
        username: self.currentUsername
      }

      this.postData(url, body)
        .then(() => this.$router.push({name: 'Leaderboards', params: { mode }}))
        .catch(err => {
          alert("There was an error!")
          console.log(err)
        })
    },
    postData(url = "", data = {}) {
      return fetch(url, {
        method: "POST",
        body: JSON.stringify(data), 
      })
    }
  },  
};
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';

#end {
    justify-content: start;
    flex-direction: column;

    & > * {
      align-self: center;
    }

  .v-btn--disabled {
    background-color: gray;
  }
}

#medal {
  max-height: 40%;
  height: 40%;
  align-self: normal;
}

#submit-text {
  font-size: large;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: center;
  background-color: #003f85;
  color: white;
  padding: 25px;
}

.score {
  margin-top: 30px;
  text-align: center;
  font-size: 70px;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  color: $textColor !important;

    @media (max-width: 400px) {
      margin-top:0;
  }
}

#submit-input-card {
  align-self: center;
  height: fit-content;

  .v-card-actions {
    justify-content: center;
  }

}

#enter-username-prompt {
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-weight: 700;   
  font-size: large;
  margin-bottom: 15px
}

</style>
