<template>
  <v-app>
    <VueNavigationBar :options="navbarOptions" @vnb-item-clicked="(t) => t == 'Home' && restartGame()"/>
    <div id="anchor" v-if="$vuetify.display.width >= 728 && isAnchorOpened">
      <div id="flag-guesser-com_728x90_s"></div>
    </div>
    <v-main>
      <div v-if="$vuetify.display.mdAndUp" class="ad-wrapper">
        <div class="ad-disclaimer">
          <div class="disclaimer-msg">Sponsored</div>
          <div id='flag-guesser-com_160x600'></div>
        </div>
      </div>
      <router-view />
      <div v-if="$vuetify.display.mdAndUp" class="ad-wrapper">
        <div class="ad-disclaimer">
          <div class="disclaimer-msg">Sponsored</div>
          <div id='flag-guesser-com_300x600'></div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import VueNavigationBar from 'vue-navigation-bar';

export default {
  name: 'App',
  components: { VueNavigationBar },
  data() {
    return {
      isAnchorOpened: true,
      isAnchorCloseAvailable: false,
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 1,
        menuOptionsLeft: [
          {
            type: "link",
            text: "Home",
            // path: { name: "Home" },
            isLinkAction: true
          },
          {
            type: "link",
            text: "Leaderboards",
            path: { name: "Leaderboards" },
            isLinkAction: false
          },
          {
            type: "link",
            text: "About",
            path: { name: "About" },
            isLinkAction: false
          }
        ]
      }
    }
  },
  mounted() {
    // CPM consent
    setTimeout(() => console.log(window.aipAPItag.showCMPScreen()), 1000)

    // Fill the ads
    window.aiptag.cmd.display.push(function() {
      window.aipDisplayTag.display('flag-guesser-com_160x600');
    });
    window.aiptag.cmd.display.push(function() {
      window.aipDisplayTag.display('flag-guesser-com_300x600');
    });
    window.aiptag.cmd.display.push(function() {
      window.aipDisplayTag.display('flag-guesser-com_728x90_s');
    });

    // Refresh ads after 60s
    setInterval(() => {
        window.aiptag.cmd.display.push(function() { window.aipDisplayTag.display('flag-guesser-com_160x600'); });
        window.aiptag.cmd.display.push(function() { window.aipDisplayTag.display('flag-guesser-com_300x600'); });
        window.aiptag.cmd.display.push(function() { window.aipDisplayTag.display('flag-guesser-com_728x90_s'); });
    }, 60000);

    setTimeout(() => this.isAnchorCloseAvailable = true, 5000)
  },
  methods: {
    restartGame() {
      this.$store.commit("restartGame");
      this.$router.push({ name: "Home" })
    },
  }
}
</script>

<style lang="scss">
@import './global.scss';
@import 'vue-navigation-bar/dist/vue-navigation-bar.css';

.v-main {
  padding-bottom: 100px !important;
  height: fit-content !important;
}

#anchor {
  position: fixed;
  width: 100%;
  height: 90px;
  bottom: 0;
  background: none;
  z-index: 10;
  display: flex;
  justify-content: center;
}

#main-navbar {
  padding: 0;
  height: 53px;
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: #191D21;

  .vnb__menu-options--left {
    padding: 0;
  }

  .vnb__menu-options {
    height: 100%;

    .vnb__menu-options__option {
      height: 100%;
      margin: 0;

      a {
        padding: 0 15px;
        height: 100%;
        color: white;
        transition: box-shadow 0.3s ease;

        &:hover {
          box-shadow: 0 0px 9px black;
        }
      }
    }
  }
}
</style>
