<template>
  <v-container>
    <h2 id="logo">Leaderboards</h2>

    <v-container height="100%" :class="'leaderboard-container ' + mode">
      <div v-for="t in tables" :key="t.name">
        <h2 class="leaderboard-name">{{ t.name }}</h2>
        <Vue3EasyDataTable
          :headers="headers"
          :items="t.items"
          alternating
          hide-footer
        >
          <template #header-username="header">
            <v-icon class="mr-2">mdi-account</v-icon>
            <span>{{ header.text }}</span>
          </template>
        </Vue3EasyDataTable>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Vue3EasyDataTable from 'vue3-easy-data-table';
const apiEndpoint = 'https://k8ghruqi04.execute-api.us-east-2.amazonaws.com'

export default {
  name: 'LeaderBoard',
  components: {Vue3EasyDataTable},
  data: () => ({
    headers: [
      {text: 'USERNAME',value: 'username'},
      {text: 'SCORE',value: 'score'}
    ], 
    survival1d: [],
    survival7d: [],
    ultimate1d: [],
    ultimate7d: [],
  }),
  computed: {
    tables() {
      let self = this;

      let survivalTables = [
        {name: "Survival daily", items: self.survival1d},
        {name: "Survival weekly", items: self.survival7d}
      ]

      let ultimateTables = [
        {name: "Ultimate daily", items: self.ultimate1d},
        {name: "Ultimate weekly", items: self.ultimate7d}
      ]
      
      if (this.mode == 'survival') {
        return survivalTables;
      } else if (this.mode == 'ultimate') {
        return ultimateTables;
      } else {
        return [...survivalTables, ...ultimateTables];
      }
    },
    mode() {
      let mode = this.$route.params.mode
      if (mode == 'survival') {
        return 'survival'
      } else if (mode == 'ultimate') {
        return 'ultimate'
      } else {
        return 'all'
      }
    }
  },
  methods: {
    getSurvivalItems() {
      fetch(apiEndpoint+"/leaderboard/survival")
      .then(resp => resp.json())
      .then(data => {
        this.survival1d = data['1d'];
        this.survival7d = data['7d'];
      })
    },
    getUltimateItems() {
      fetch(apiEndpoint+"/leaderboard/ultimate")
      .then(resp => resp.json())
      .then(data => {
        this.ultimate1d = data['1d'];
        this.ultimate7d = data['7d'];
      })
    },
  },
  mounted() {
    if (this.mode == 'survival') {
      this.getSurvivalItems()
    } else if (this.mode == 'ultimate') {
      this.getUltimateItems()
    } else {
      this.getSurvivalItems()
      this.getUltimateItems()
    }
  },
  watch: {
    mode(newMode) {
    if (newMode == 'survival') {
      this.getSurvivalItems()
    } else if (newMode == 'ultimate') {
      this.getUltimateItems()
    } else {
      this.getSurvivalItems()
      this.getUltimateItems()
    }
    }
  }
}
</script>

<style lang="scss">
@import 'vue3-easy-data-table/dist/style.css';
.leaderboard-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  &.all {
    grid-template-rows: 1fr 1fr;
  }
}

.leaderboard-name {
  text-align: center;
  font-size: xx-large;
}

.vue3-easy-data-table {
  border: none !important;
}

.vue3-easy-data-table div.vue3-easy-data-table__main {
  border-radius: 5px;
  min-height: 396px;
   * {
    font-size: 20px;
   }
}
</style>