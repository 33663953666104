<template>
  <v-container id="about">
    <h1 id="logo">About</h1>
    <br />
    <p>
        <strong>Flag guesser</strong>
        is a fun way to test your knowledge of world flags. There are three game modes:
    </p>
    <br />
    <p>
        <strong>Practice</strong>
        This is s a casual mode where you are is given 20 randomly selected flags. At the end of a game you get the medal based on the achieved score.
    </p>
    <br />  
    <p>
        <strong>Survival</strong>
        This mode adds constraint of 5 lives. The further the you go, the higher the score. This mode has daily and weekly leaderboards that let you compare yourself with other players.
    </p>
    <br />
    <p>
        <strong>Ultimate</strong>
        In this mode, your true flag knowledge comes to a test. Similar to Survival mode, you are given 5 lives, but each flag starts with a small part of it being visible.
        You can reval more of the flag, but with each reveal, your points for that flag decrease. There is also a leadboard that lets you compare your results with others.
    </p>



  </v-container>
</template>

<script>
export default {
  name: 'AboutPage'
}
</script>

<style>
#about {
  justify-content: start;
}
</style>